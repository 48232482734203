import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/hbfpagelayout"
import speakerimg from "../images/speaker.png"

const ProgramCommittee = () => {
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
  })
  const data = useStaticQuery(graphql`
    query {
      contentfulProgramCommittee {
        title
        event {
          title
          startDate(formatString: "MMMM D")
          endDate(formatString: "MMMM D, YYYY")
          transparentHeader
          backgroundImage {
            file {
              url
            }
          }
          bannerImageLeft {
            file {
              url
            }
          }
          bannerImageRight {
            file {
              url
            }
          }
          bannerImageLeftMobile {
            file {
              url
            }
          }
          bannerImageRightMobile {
            file {
              url
            }
          }
        }
        committeeMembers {
          title
          items {
            title
            speakers {
              name
              designation
              organisation
              bio {
                bio
              }
              image {
                file {
                  url
                }
              }
            }
          }
        }
        seoMetadata {
          title
          description {
            description
          }
        }
      }
    }
  `)
  const [selectedCommitteeMember, setSelectedCommitteeMember] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const committeeMemberPopupOpen = committeeMember => () => {
    setSelectedCommitteeMember(committeeMember)
    document.getElementById("event_popup").style.display = "flex"
  }
  const committeeMemberPopupClose = () => {
    document.getElementById("event_popup").style.display = "none"
  }

  useEffect(() => {
    const scroll = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", scroll)
    const close = e => {
      if (e.keyCode === 27) {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("keydown", close)
    return () => window.removeEventListener("keydown", close)
  }, [])

  useEffect(() => {
    const close = e => {
      if (e.target.id === "event_popup") {
        document.getElementById("event_popup").style.display = "none"
      }
    }
    window.addEventListener("mousedown", close)
    return () => window.removeEventListener("mousedown", close)
  }, [])

  return (
    <PageLayout
      title={data?.contentfulProgramCommittee?.title}
      backgroundImage={data?.contentfulProgramCommittee?.event?.backgroundImage}
      bannerImageLeft={data?.contentfulProgramCommittee?.event?.bannerImageLeft}
      bannerImageLeftMobile={
        data?.contentfulProgramCommittee?.event?.bannerImageLeftMobile
      }
      bannerImageRight={
        data?.contentfulProgramCommittee?.event?.bannerImageRight
      }
      bannerImageRightMobile={
        data?.contentfulProgramCommittee?.event?.bannerImageRightMobile
      }
      transparentHeader={
        data?.contentfulProgramCommittee?.event?.transparentHeader
      }
      scroll={scrollPosition}
    >
      <Head
        title={data?.contentfulProgramCommittee?.seoMetadata?.title}
        description={
          data?.contentfulProgramCommittee?.seoMetadata?.description
            ?.description
        }
      />
      <div className="speaker_body">
        {data?.contentfulProgramCommittee?.committeeMembers?.items?.map(
          (eve, index) => (
            <>
              <div className="speaker_body_title">{eve?.title}</div>
              <div className="speaker_thumbs">
                {eve?.speakers?.map((item, index) => {
                  return (
                    <div
                      className="speaker_thumb"
                      onClick={committeeMemberPopupOpen(item)}
                    >
                      <div className="speaker_photo">
                        <img
                          src={
                            item?.image?.file?.url
                              ? item?.image?.file?.url
                              : speakerimg
                          }
                          alt={item?.name}
                        />
                      </div>
                      <div className="speaker_data">
                        <h4 className="speaker_name">{item?.name}</h4>
                        <p className="speaker_info">
                          {item.designation && <>{item?.designation}</>}
                          {item.designation && item?.organisation && (
                            <>,</>
                          )}{" "}
                          {item?.organisation}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
          )
        )}
      </div>
      <div className="event_popup" id="event_popup">
        <div className="event_popup_body">
          <div className="event_popup_close">
            <span onClick={committeeMemberPopupClose}>X</span>
          </div>
          <div className="event_popup_speaker">
            <div className="speaker_photo">
              <img
                src={
                  selectedCommitteeMember?.image?.file?.url
                    ? selectedCommitteeMember?.image?.file?.url
                    : speakerimg
                }
                alt="speakerheadshot"
              />
            </div>
            <div className="speaker_data">
              <div className="speaker_name">
                {selectedCommitteeMember?.name}
              </div>
              <div className="speaker_info">
                {selectedCommitteeMember?.designation && (
                  <>{selectedCommitteeMember?.designation}</>
                )}
                {selectedCommitteeMember?.designation &&
                  selectedCommitteeMember?.organisation && <>,</>}{" "}
                {selectedCommitteeMember?.organisation}
              </div>
            </div>
          </div>
          <div
            className="event_popup_info"
            style={{ whiteSpace: "pre-wrap", marginTop: "15px" }}
          >
            {selectedCommitteeMember?.bio?.bio}
          </div>
        </div>
      </div>
    </PageLayout>
  )
}
export default ProgramCommittee
