import React, { useState, useEffect } from "react"
import "../styles/index.scss"
import Header from "./homepageheader"
import Banner from "./banner"

import Footer from "./footer"
const HbfPageLayout = props => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = window.location.search
      if (!params) {
        const storedParams = sessionStorage.getItem("params")
        if (storedParams) {
          window.history.replaceState(
            {},
            "",
            window.location.href + storedParams
          )
        }
        return
      }
      sessionStorage.setItem("params", params)
    }
  }, [])
  return (
    <>
      <div className="main_wrap">
        <div className="container">
          <div className="homepage_header">
            <img
              src={props?.backgroundImage?.file?.url}
              className="banner_background"
            />
            <div className="banner_data">
              <Header
                transparentHeader={props?.transparentHeader}
                scrollPosition={props?.scroll}
                color="white"
              />
              <Banner
                title={props.title}
                date={props.date}
                bannerImageLeft={props?.bannerImageLeft}
                bannerImageLeftMobile={props?.bannerImageLeftMobile}
                bannerImageRight={props?.bannerImageRight}
                bannerImageRightMobile={props?.bannerImageRightMobile}
                transparentHeader={props.transparentHeader}
              />
            </div>
          </div>

          {props.children}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default HbfPageLayout
